
import Vue from "vue";
import FileUploader from "@/components/form-components/FileUploader.vue";
import store from "@/store";
import rules from "@/services/helpers/validation-rules";
import MultiplePhonePicker from "@/components/form-components/MultiplePhonePicker.vue";

type UserRole = {
  subdivision: number;
  id: number;
};

export default Vue.extend({
  name: "CreateUser",

  components: {
    MultiplePhonePicker,
    FileUploader
  },

  props: {
    isDialog: {
      default: false,
      type: Boolean,
      required: false
    },
    isEmployee: {
      default: false,
      type: Boolean,
      required: false
    },
    value: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },

  data: () => ({
    rules,
    model: {
      name: "" as string,
      email: "" as string,
      phones: [null] as Array<any>,
      roles: [{}] as Array<UserRole | any>
    } as any,
    allRoles: [] as Array<{}>,
    errorMessages: {} as any,
    subdivisions: [] as Array<{}>,
    showPassword: {
      password: false as boolean,
      confirmPassword: false as boolean
    } as any,
    loading: false as boolean
  }),

  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.isEmployee) {
          this.model.phones = this.value.phones;
          this.model.email = this.value.email;
        }
        if (
          this.value &&
          this.value.contacts &&
          this.value.contacts.length > 0
        ) {
          this.model.phones = this.value.contacts
            .filter((item: any) => item.type === "phone")
            .map((item: any) => item.value);

          if (this.value.contacts.some((item: any) => item.type === "email")) {
            const [email] = this.value.contacts
              .filter((item: any) => item.type === "email")
              .map((item: any) => item.value);
            this.model.email = email;
          }
        }
        if (this.value[`name_${this.currentLanguage}`]) {
          this.model.name = this.value[`name_${this.currentLanguage}`];
        }
      }
    }
  },

  computed: {
    authorisedUser() {
      return this.$store.getters["user/info"];
    },
    isSamePassword() {
      return (
        this.model.password === this.model.password_confirmation ||
        this.$t("users.form.validation.not_match")
      );
    }
  },

  async beforeMount() {
    try {
      const response = await this.$API.subdivisions().getList();
      this.subdivisions = response.items;
      this.allRoles = await this.$API.roles().getList();
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  methods: {
    addRole() {
      this.model.roles.push({
        subdivision_id: null,
        id: null
      });
    },
    removeRole(index: number) {
      this.model.roles.splice(index, 1);
    },
    prepareModel() {
      return {
        ...this.model,
        phones: this.model.phones.filter((el: string) => el),
        roles: this.model.roles.filter((el: UserRole) => el.id),
        position_id: this.model.roles[0]?.id
      };
    },
    changeView(key: string): void {
      this.showPassword[key] = !this.showPassword[key];
    },
    generateRandomPassword() {
      this.showPassword.password = true;
      this.model.password = Array(10)
        .fill(
          "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz+!@#$%&"
        )
        .map((x: any) => {
          return x[Math.floor(Math.random() * x.length)];
        })
        .join("");
    },
    async submit(): Promise<void> {
      this.loading = true;
      try {
        this.errorMessages = {};
        if ((this.$refs.form as any).validate()) {
          const model = { ...this.model };

          if (this.isDialog || this.isEmployee) {
            let user;
            if (this.isDialog) {
              user = await this.$API.users().createBeneficiary(model);
            } else {
              const [position] = model.roles;
              model.position_id = position?.id;
              user = await this.$API.users().create(model);
            }
            this.$emit("input", { value: user.id, text: user.name });
            this.$emit("close");
            return;
          }

          const [position] = model.roles;
          model.position_id = position?.id;

          await this.$API.users().create(this.prepareModel());
          if (this.authorisedUser.id === this.model.id) {
            await this.$store.dispatch("user/set", this.user);
          }
          await this.$router.push("/users");
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_create")
          );
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
          //todo pentru ce trebuie aici timeout, daca se poate de facut this.errorMessages = {}; la inceput?
          // setTimeout(() => {
          //   this.errorMessages = {};
          // }, 2000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    async closeOrGoForward(): Promise<void> {
      if (this.isDialog || this.isEmployee) {
        await this.$emit("close");
      } else {
        await this.$router.push("/users");
      }
    }
  }
});
