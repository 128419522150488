
import Vue from "vue";
import FileUploader from "@/components/form-components/FileUploader.vue";
import API from "@/api/API";
import store from "@/store";
import rules from "@/services/helpers/validation-rules";
import MultiplePhonePicker from "@/components/form-components/MultiplePhonePicker.vue";

type UserRole = {
  subdivision_id: number;
  id: number;
};

export default Vue.extend({
  name: "UserUpdate",

  components: {
    MultiplePhonePicker,
    FileUploader
  },

  data: () => ({
    rules,
    model: {
      name: "" as string,
      email: "" as string,
      phones: [],
      roles: [] as Array<UserRole>
    } as any,
    allRoles: [] as Array<{}>,
    errorMessages: {} as any,
    subdivisions: [] as Array<{}>
  }),
  computed: {
    authorisedUser() {
      return this.$store.getters["user/info"];
    }
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const item = await API.users().getForEdit(to.params.id);
      const subdivisions = await API.subdivisions().getList();
      const allRoles = await API.roles().getList();

      next(vm => {
        vm.model = { ...item };

        if (!vm.model.roles) {
          vm.model.roles = [];
        }

        vm.subdivisions = subdivisions.items;
        vm.allRoles = allRoles;

        vm.$nextTick(() => {
          vm.fullLoaded = true;
        });
      });
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  methods: {
    addRole() {
      this.model.roles.push({
        subdivision_id: null,
        id: null
      });
    },
    removeRole(index: number) {
      this.model.roles.splice(index, 1);
    },
    prepareModel() {
      return {
        name: this.model.name,
        email: this.model.email,
        phones: this.model.phones.filter((el: string) => el),
        roles: this.model.roles.filter((el: UserRole) => el.id)
      };
    },
    async submit(): Promise<void> {
      try {
        this.errorMessages = {};
        if ((this.$refs.form as any).validate()) {
          await this.$API
            .users()
            .edit(this.$route.params.id, this.prepareModel());
          if (this.authorisedUser.id === this.model.id) {
            await this.$store.dispatch("user/set", this.user);
          }
          await this.$router.push("/users");
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_edit")
          );
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
          //todo pentru ce trebuie aici timeout, daca se poate de facut this.errorMessages = {}; la inceput?
          // setTimeout(() => {
          //   this.errorMessages = {};
          // }, 2000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
    }
  }
});
